import classnames from 'classnames';
import Pulse from '../Pulse';
import React from 'react';
import styles from './styles.module.scss';

interface Props {
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (e: any) => void;
  style?: React.CSSProperties;
  textStyle?: React.CSSProperties;
  title: string | JSX.Element;
  type?: 'button' | 'submit' | 'reset' | undefined;
  withShadow?: boolean;
  reverseGradient?: boolean;
}

export default function PrimaryButton({
  className,
  disabled,
  loading,
  onClick,
  reverseGradient,
  style,
  textStyle,
  title,
  type,
  withShadow,
}: Props): JSX.Element {
  return (
    <div className={classnames(styles.container, className)}>
      <button
        className={classnames('clickable', styles.auraBtn, className, {
          [styles.reverseGradient]: reverseGradient,
        })}
        disabled={disabled || loading}
        style={style}
        type={type || 'button'}
        onClick={onClick}>
        {loading ? (
          <Pulse color="white" size={24} />
        ) : (
          <>
            <div>
              <p style={textStyle}>{title}</p>
            </div>
          </>
        )}
      </button>
      {withShadow && !disabled && <div className={styles.shadow}></div>}
    </div>
  );
}
