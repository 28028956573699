import BaseLayout from '../../layouts/BaseLayout';
import { checkAuthorizedForFeature } from '@/components/app/RestrictedFeature';
import features from '@/utils/constants/features';
import Loader from '@/components/app/Loader';
import Logger from '@/services/Logger';
import LoginCard from '../../components/login';
import routeConstants from '@/utils/constants/routes';
import styles from './styles.module.scss';
import useAuthUser from '@/hooks/authUser';
import usePageQuery from '@/hooks/pageQuery';
import { useRouter } from 'next/router';

function LoginPage() {
  const Router = useRouter();

  const { businessUser } = useAuthUser();
  const { asPath, pathname } = usePageQuery();
  const canAccessPlatform = checkAuthorizedForFeature({
    feature: features.PLATFORM_ACCESS,
    user: businessUser,
  });

  if (businessUser && businessUser.id) {
    if (canAccessPlatform) {
      if (
        pathname &&
        pathname !== routeConstants.PAGE_LOGIN &&
        pathname !== routeConstants.PAGE_RESTRICTED
      ) {
        if (typeof pathname === 'string' && typeof asPath === 'string')
          Router.push(pathname, asPath);
      } else {
        Router.push(routeConstants.PAGE_DASHBOARD);
      }
    } else {
      Logger.debug('Redirecting to restricted');
      Router.push(routeConstants.PAGE_RESTRICTED);
    }
  }

  if (canAccessPlatform) {
    return (
      <BaseLayout hideBackground>
        <Loader />
      </BaseLayout>
    );
  }

  return (
    <BaseLayout hideBackground>
      <div className={styles.container}>
        <div className={styles.loginCardWrapper}>
          <h1 className={styles.title}>Welcome to Aura</h1>

          <LoginCard style={{ width: '90%' }} />
        </div>
      </div>
    </BaseLayout>
  );
}

export default LoginPage;
