import Auth from '@/services/Auth';
import authConstants from '@/utils/constants/auth';
import Input from '@AppComponents/Input';
import { isValidEmail } from '@Utils/validators';
import LoginProps from '../login.types';
import { notifyHandledError } from '@/services/ErrorMonitoring';
import PrimaryButton from '@AppComponents/PrimaryButton';
import routeConstants from '@/utils/constants/routes';
import Router from 'next/router';
import styles from './styles.module.scss';
import useToastMessage from '@Hooks/toastMessage';
import React, { useReducer } from 'react';

interface StateType {
  email: string;
  givenName: string;
  password: string;
}

interface ActionType {
  data: string;
  type: string;
}

interface Props {
  btnText?: string;
  isSignUp?: boolean;
  showErrorToasts?: boolean;
  showForgotPassword?: boolean;
  onSubmit?: (data: LoginProps) => void;
}

const initialState: StateType = {
  email: '',
  givenName: '',
  password: '',
};

function reducer(state: StateType, action: ActionType) {
  switch (action.type) {
    case 'updateEmail':
      return { ...state, email: action.data };
    case 'updatePassword':
      return { ...state, password: action.data };
    case 'updateName':
      return { ...state, givenName: action.data };
    default:
      return state;
  }
}

export default function EmailLoginForm({
  btnText,
  isSignUp,
  onSubmit,
  showErrorToasts = true,
  showForgotPassword = true,
}: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const Toast = useToastMessage();
  const { email, givenName, password } = state;

  function onChangePassword(evt: React.ChangeEvent<HTMLInputElement>) {
    dispatch({ data: evt.target.value, type: 'updatePassword' });
  }

  function onChangeEmail(evt: React.ChangeEvent<HTMLInputElement>) {
    dispatch({ data: evt.target.value?.trim(), type: 'updateEmail' });
  }

  function onChangeName(evt: React.ChangeEvent<HTMLInputElement>) {
    dispatch({ data: evt.target.value, type: 'updateName' });
  }

  async function handleSubmit(evt: React.FormEvent) {
    evt.preventDefault();
    if (isSignUp && (!givenName || givenName.trim() === '')) {
      handleError('Please enter a valid name');
      return;
    }
    if (!isValidEmail(email)) {
      handleError('Please enter a valid email');
      return;
    }
    if (!password || password === '') {
      handleError('Please enter password');
      return;
    }
    if (isSignUp && password.length < 7) {
      handleError('Password must be 7+ characters');
      return;
    }
    if (onSubmit) {
      onSubmit({ email, givenName, password });
    }
    if (!isSignUp) {
      try {
        const authResponse = await Auth.login({
          email,
          password,
          provider: authConstants.PROVIDER_PASSWORD,
        });
        if (authResponse) {
          Router.replace(routeConstants.PAGE_DASHBOARD);
        } else {
          notifyHandledError(new Error('Error while authentication'), {
            message: 'Error while authentication',
          });
          Toast.showError(
            'Failed to authenticate. Please make sure credentials are correct.'
          );
        }
      } catch (error) {
        notifyHandledError(error as Error, {
          message: 'Error while authentication',
        });
        Toast.showError('Error in authentication');
      }
    }
  }

  function handleError(errorMessage: string) {
    if (showErrorToasts) {
      Toast.showError(errorMessage);
    }
  }

  return (
    <form className={styles.container} onSubmit={handleSubmit}>
      {isSignUp && (
        <Input
          className={styles.input}
          placeholder="First Name"
          style={{ width: '100%' }}
          type="text"
          value={givenName}
          onChange={onChangeName}
        />
      )}
      <Input
        className={styles.input}
        placeholder="Email"
        style={{ width: '100%' }}
        type="text"
        value={email}
        onChange={onChangeEmail}
      />
      <Input
        className={styles.input}
        placeholder="Password"
        style={{ width: '100%' }}
        type="password"
        onChange={onChangePassword}
      />
      <PrimaryButton
        className={styles.siginButton}
        style={{
          background: 'linear-gradient(180deg,#48f2f4,#04aaf4 74%)',
        }}
        title={btnText || 'Log In'}
        type="submit"
        onClick={handleSubmit}
      />
      {showForgotPassword && !isSignUp && (
        <span
          className={styles.forgotPasswordText}
          // onClick={handleForgotPassword}
        >
          Forgot your password?
        </span>
      )}
    </form>
  );
}
