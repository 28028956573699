import classnames from 'classnames';
import config from '@/config';
import EmailLoginForm from './EmailLoginForm';
import FacebookLoginBtn from './FacebookLoginBtn';
import GoogleLoginBtn from './GoogleLoginBtn';
import { GoogleOAuthProvider } from '@react-oauth/google';
import LoginProps from './login.types';
import styles from './styles.module.scss';
import React, { useState } from 'react';

interface Props {
  buttonText?: string;
  hideCard?: boolean;
  isSignup?: boolean;

  onSubmit?: (data: LoginProps) => void;
  style?: React.CSSProperties;
}

export default function LoginCard({
  buttonText,
  hideCard,
  isSignup,
  onSubmit,
  style,
}: Props) {
  const [isSignupActive, setSignupActive] = useState(isSignup);

  return (
    <div
      className={classnames('w-100', styles.loginCard, { card: !hideCard })}
      style={style}>
      <div
        className={classnames(
          'col align-center justify-center',
          styles.header
        )}>
        <>
          <GoogleOAuthProvider clientId={config.google.authClientId}>
            <GoogleLoginBtn style={{ marginBottom: 12 }} />
          </GoogleOAuthProvider>
          {/* <FacebookLoginBtn /> */}

          <span className={styles.loginDivider}>OR</span>
        </>

        <EmailLoginForm
          btnText={buttonText || (isSignupActive ? 'Sign Up' : 'Sign In')}
          isSignUp={isSignup && isSignupActive}
          showForgotPassword={false}
          onSubmit={onSubmit}
        />
      </div>
    </div>
  );
}
