import Auth from '@/services/Auth';
import authConstants from '@/utils/constants/auth';
import classnames from 'classnames';
import Image from 'next/image';
import { notifyHandledError } from '@/services/ErrorMonitoring';
import Pulse from '@/components/app/Pulse';
import routeConstants from '@/utils/constants/routes';
import Router from 'next/router';
import styles from './styles.module.scss';
import { useGoogleLogin } from '@react-oauth/google';
import useToastMessage from '../../../hooks/toastMessage';
interface Props {
  btnText?: string;
  showErrorToasts?: boolean;
  style?: React.CSSProperties;
  textStyle?: React.CSSProperties;
  loading?: boolean;
  onSuccessHandler?: (authData: {
    displayName: string;
    email: string;
    id: string;
  }) => void;
}

export default function GoogleLoginBtn({
  btnText,
  loading,
  onSuccessHandler,
  showErrorToasts = true,
  style,
  textStyle,
}: Props) {
  const Toast = useToastMessage();

  const login = useGoogleLogin({
    flow: 'auth-code',
    onError: (errorResponse) => {
      const { error } = errorResponse;
      notifyHandledError(new Error(error), {
        message: 'Error in Google authentication',
      });
      if (showErrorToasts) Toast.showError('Error in Google authentication');
    },
    onSuccess: async (codeResponse) => {
      const { code } = codeResponse;
      if (code) {
        const authResponse = await Auth.signUpOrLogin({
          authCode: code,
          provider: authConstants.PROVIDER_GOOGLE,
        });
        if (authResponse) {
          if (onSuccessHandler) {
            onSuccessHandler(authResponse);
          } else {
            Router.replace(routeConstants.PAGE_DASHBOARD);
          }
        } else {
          notifyHandledError(new Error('Error while Google authentication'), {
            message: 'Error while Google authentication',
          });
          Toast.showError('Failed to authenticate with Google');
        }
      } else {
        notifyHandledError(new Error('Error Google while authentication'), {
          message: 'Error while Google authentication',
        });
        Toast.showError('Error authenticating with Google');
      }
    },
    scope: 'https://www.googleapis.com/auth/userinfo.email',
  });

  return (
    <button
      className={classnames('clickable', styles.googleBtn)}
      style={style}
      onClick={login}>
      {loading && <Pulse color="#03a9f4" size={24} />}
      {!loading && (
        <>
          <Image
            alt="Google icon"
            className={styles.googleIcon}
            height={22}
            src="/icons/google.png"
            width={22}
          />

          <span className={styles.googleBtnText} style={{ ...textStyle }}>
            {btnText || 'Continue with Google'}
          </span>
        </>
      )}
    </button>
  );
}
