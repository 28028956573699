import classnames from 'classnames';
import { forwardRef } from 'react';
import styles from './styles.module.scss';

interface Props {
  className?: string;
  error?: string | boolean;
  value?: string;
  placeholder?: string;
  type?: string;
  style?: object;
  disabled?: boolean;
  name?: string;
  showHint?: boolean;
  containerClassName?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
export type Ref = HTMLInputElement;

const Input = forwardRef<Ref, Props>((props, ref) => {
  const {
    className,
    containerClassName,
    disabled,
    error,
    placeholder,
    showHint,
    style,
    value,
    ...restProps
  } = props;

  return (
    <div
      className={classnames(styles.container, styles.containerClassName)}
      style={style}>
      <input
        ref={ref}
        className={classnames(styles.input, className, {
          [styles.error]: !!error,
        })}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        {...restProps}
      />
      {showHint && (
        <div className={styles.inputHints}>
          <span className={styles.errorMsg}>{error}</span>
        </div>
      )}
    </div>
  );
});

Input.displayName = 'Input';

export default Input;
